/*
***** [ PARTS ] *****

::: sample :::
<p class="contactBtn"><a href="#">お問い合わせ</a></p>

*/
.contactBtn{
	text-align: center;

	a{
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		width: 300px;
		height: 55px;
		color: #333333;
		font-size: 18px;
		font-weight: 700;
		line-height: 1;
		text-decoration: none;
		background: #ffe09f;
		background: -moz-linear-gradient(left,  #ffe09f 0%, #ffc447 50%, #ffc447 50%, #ffad00 100%);
		background: -webkit-linear-gradient(left,  #ffe09f 0%,#ffc447 50%,#ffc447 50%,#ffad00 100%);
		background: linear-gradient(to right,  #ffe09f 0%,#ffc447 50%,#ffc447 50%,#ffad00 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffe09f', endColorstr='#ffad00',GradientType=1 );
		background-color: #FFE09F;
		border-radius: 6px;
		position: relative;
		transition: all 0.3s ease;

		&::after{
			content: "";
			display: block;
			width: 20px;
			height: 20px;
			background-image: url(../images/common/icon_arw_btn1.svg);
			background-repeat: no-repeat;
			background-position: 0 0;
			background-size: contain;
			position: absolute;
			right: 20px;
			top: 50%;
			transition: all 0.3s ease;
			transform: translateY(-50%);
		}

		&:hover{
			background: #ffe09f;
			background-color: #FFE09F;
			opacity: 1;

			&::after{
				right: 15px;
			}
		}
	}
}


/*
***** [ PARTS ] *****

::: sample :::
<p id="pagetop"><a href="#"><span>ページトップ</span></a></p>

*/
#pagetop {
	display: block;
	width: 50px;
	height: 50px;
	position: fixed;
	right: 10px;
	bottom: 70px;
	z-index: 6;
	transition: all 0.3s ease;

	a{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		color: #FFF;
		text-decoration: none;
		background-color: #0082D6;
		background-image: url(../images/common/page_top.svg);
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: 37px auto;
		border-radius: 50%;
		visibility: hidden;
		opacity: 0;
		transition: all 0.8s ease;

		&.view{
			visibility: visible;
			opacity: 1;
		}

		&:hover{
			background-color: #008EEB;
			opacity: 1;
		}
	}
}
@media screen and (min-width: 768px) {
	#pagetop {
		width: 70px;
		height: 70px;

		a{
			background-size: 50px auto;
		}
	}
}


/*
***** [ PARTS ] *****

::: sample :::
<a href="#" class="e_btn">xxxxxxxxxx</a>

*/
.e_btn{
	display: flex;
	align-items: center;
	justify-content: center;
	padding-right: 20px;
	width: 250px;
	height: 50px;

	color: #FFF;
	font-size: 15px;
	font-weight: 500;
	line-height: 1;
	text-decoration: none;

	background: #0082d6;
	background: -moz-linear-gradient(left,  #0082d6 0%, #0072BC 50%, #00548b 100%);
	background: -webkit-linear-gradient(left,  #0082d6 0%,#0072BC 50%,#00548b 100%);
	background: linear-gradient(to right,  #0082d6 0%,#0072BC 50%,#00548b 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0082d6', endColorstr='#00548b',GradientType=1 );
	border: 1px solid #FFF;
	border-radius: 5px;
	position: relative;

	&::after{
		content: "";
		display: block;
		width: 20px;
		height: 20px;
		background-image: url(../images/common/icon_arw_btn2.svg);
		background-repeat: no-repeat;
		background-position: 0 0;
		background-size: contain;
		position: absolute;
		right: 20px;
		top: 50%;
		transition: all 0.3s ease;
		transform: translateY(-50%);
	}

	&:hover{
		opacity: 1;
		background: #008EEB;
		border: 1px solid #008EEB;

		&::after{
			right: 15px;
		}
	}

	//--------------------------
	&.type01{
		width: 250px;
		height: 50px;
	}

	//--------------------------
	&.type02{
		width: 300px;
		height: 50px;
	}

	//--------------------------
	&.type03{
		width: 100%;
		height: 45px;
		font-size: 13px;
		background: #0072BC;

		&::after{
			width: 14px;
			height: 14px;
			right: 10px;
		}

		&:hover{
			opacity: 1;
			background: #008EEB;
			border: 1px solid #008EEB;

			&::after{
				right: 5px;
			}
		}
	}

	//--------------------------
	&.type_blank{

		&::after{
			width: 20px;
			height: 13px;
			background-image: url(../images/common/icon_blank_white.svg);
		}
	}
}


/*
***** [ PARTS ] *****

::: sample :::
<a href="#" class="p_btn">xxxxxxxxxx</a>

*/
.p_btn{
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
	width: 320px;
	height: 70px;
	margin: 10px;

	color: #0082d6;
	font-size: 15px;
	font-weight: 500;
	line-height: 1;
	text-decoration: none;

	background: #fff;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0082d6', endColorstr='#00548b',GradientType=1 );
	border: 1px solid #FFF;
	border-radius: 5px;
	position: relative;

	&::after{
		content: "";
		display: block;
		width: 20px;
		height: 20px;
		background-image: url(../images/common/icon_pdf_btn.svg);
		background-repeat: no-repeat;
		background-position: 0 0;
		background-size: contain;
		position: absolute;
		right: 20px;
		top: 50%;
		transition: all 0.3s ease;
		transform: translateY(-50%);
	}

	&:hover{
		opacity: 1;

		&::after{
			right: 15px;
		}
	}

	//--------------------------
	&.type01{
		width: 250px;
		height: 50px;
	}

}
@media screen and (min-width: 992px) {
	.e_btn{
		width: 408px;
		height: 72px;
		font-size: 20px;

		&::after{
			width: 24px;
			height: 24px;
			right: 30px;
		}

		&:hover{
			&::after{
				right: 25px;
			}
		}

		//--------------------------
		&.type01{
			width: 408px;
			height: 72px;
		}

		//--------------------------
		&.type03{
			width: 100%;
			height: 62px;
			font-size: 18px;

			&::after{
				width: 20px;
				height: 20px;
				right: 28px;
			}

			&:hover{
		
				&::after{
					right: 23px;
				}
			}
		}

		&.type04{
			width: 100%;
			height: 62px;
			font-size: 18px;
			background-image: url(../images/common/icon_pdf_btn.svg);
	
			&::after{
				width: 20px;
				height: 20px;
				right: 28px;
			}
	
			&:hover{			
		
				&::after{
					right: 23px;
				}
			}
		}

	}
}


/*
***** [ PARTS ] *****

::: sample :::
<a href="#" class="e_btn_arw">xxxxxxxxxx</a>

*/
.e_btn_arw{
	display: block;
	padding-right: 20px;
	position: relative;

	&::after{
		content: "";
		display: block;
		width: 20px;
		height: 20px;
		background-image: url(../images/common/icon_arw_btn3.svg);
		background-repeat: no-repeat;
		background-position: 0 0;
		background-size: contain;
		position: absolute;
		right: 5px;
		top: 50%;
		transition: all 0.3s ease;
		transform: translateY(-50%);
	}

	&:hover{
		color: #0082D6;
		background-color: #EFF9FF;

		&::after{
			right: 0;
		}
	}
}
@media screen and (min-width: 992px) {
	.e_btn_arw{

		&::after{
			width: 24px;
			height: 24px;
		}
	}
}


/*
***** [ PARTS ] *****

::: sample :::
<a href="#" class="e_btn_arw_2">xxxxxxxxxx</a>

*/
.e_btn_arw_2{
	display: block;
	padding-right: 33px;
	position: relative;

	&::after{
		content: "";
		display: block;
		width: 18px;
		height: 18px;
		background-image: url(../images/common/icon_arw_btn3.svg);
		background-repeat: no-repeat;
		background-position: 0 0;
		background-size: contain;
		position: absolute;
		right: 5px;
		top: 50%;

		transition: all 0.3s ease;
		transform: translateY(-50%);
	}

	&:hover{
		color: #0082D6;

		&::after{
			right: 0;
		}
	}
}



/*
***** [ PARTS ] *****

::: sample :::
<a href="#" class="e_btn_anchor">xxxxxxxxxx</a>

*/
.e_btn_anchor{
	display: flex;
	align-items: center;
	padding: 0 45px 0 0;
	width: 100%;
	height: 56px;
	color: #333;
	font-size: 15px;
	font-weight: 500;
	line-height: 1.57;
	letter-spacing: 0;
	text-decoration: none;
	border-bottom: 1px solid #c0dbec;
	position: relative;

	&::after{
		content: "";
		display: block;
		width: 20px;
		height: 20px;
		background-image: url(../images/common/icon_arw_btn3.svg);
		background-repeat: no-repeat;
		background-position: 0 0;
		background-size: contain;
		position: absolute;
		right: 5px;
		top: 50%;
		transition: all 0.3s ease;
		transform: translateY(-50%) rotate(90deg);
	}

	&:hover{
		color: #0082D6;
		background-color: #EFF9FF;
		opacity: 1;

		&::after{
			top: 54%;
		}
	}
}
@media screen and (min-width: 992px) {
	.e_btn_anchor{
		padding: 0 35px 0 5px;
		font-size: 18px;
		letter-spacing: 0.05em;

		&::after{
			width: 24px;
			height: 24px;
			right: 10px;
			top: 47%;
		}
	}
}


/*
***** [ PARTS ] *****

::: sample :::
<a href="#" class="e_btn_back">xxxxxxxxxx</a>

*/
.e_btn_back{
	display: inline-block;
	padding: 11px 0 11px 23px;
	width: 100%;
	color: #333;
	font-size: 14px;
	font-weight: 500;
	line-height: 1;
	letter-spacing: 0;
	text-decoration: none;
	position: relative;

	&::after{
		content: "";
		display: block;
		width: 18px;
		height: 18px;
		background-image: url(../images/common/icon_arw_btn3.svg);
		background-repeat: no-repeat;
		background-position: 0 0;
		background-size: contain;
		position: absolute;
		left: 0;
		top: 50%;
		transition: all 0.3s ease;
		transform: translateY(-50%) rotate(180deg);
	}
}
@media screen and (min-width: 992px) {
	.e_btn_back{
		padding: 11px 0 11px 40px;
		font-size: 16px;

		&::after{
			width: 20px;
			height: 20px;
			left: 10px;
		}

		&:hover{
			opacity: 1;

			&::after{
				left: 0;
			}
		}
	}
}


.e_btn_no_arw{
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	width: 100%;
	height: 50px;
	max-width: 300px;

	color: #FFF;
	font-size: 15px;
	font-weight: 500;
	line-height: 1;
	letter-spacing: 0.15em;
	text-decoration: none;

	background: #0082d6;
	background: -moz-linear-gradient(left,  #0082d6 0%, #0072BC 50%, #00548b 100%);
	background: -webkit-linear-gradient(left,  #0082d6 0%,#0072BC 50%,#00548b 100%);
	background: linear-gradient(to right,  #0082d6 0%,#0072BC 50%,#00548b 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0082d6', endColorstr='#00548b',GradientType=1 );
	border: 1px solid #FFF;
	border-radius: 5px;

	&:hover{
		opacity: 1;
		background: #008EEB;
		border: 1px solid #008EEB;
	}
}
@media screen and (min-width: 992px) {
	.e_btn_no_arw{
		height: 72px;
		max-width: 408px;
		font-size: 24px;
		letter-spacing: 0.05em;
	}
}


/*
***** [ PARTS ] *****

::: sample :::
<span class="marker">xxx</span>

*/
.marker{
	background: linear-gradient(transparent 70%, #FFF2D1 30%);
}


/*
***** [ PARTS ] *****

::: sample :::
<a href="#" target="_blank" class="blank">xxx</a>

*/
a.blank{
	display: inline;
	padding-right: 30px;
	color: #0072BC;
	background-image: url(../images/common/icon_blank_blue.svg);
	background-repeat: no-repeat;
	background-position: 98% 50%;
	background-size: 20px 13px;
}
a.blank.red{
	color: #E72929;
	background-image: url(../images/common/icon_blank_red.svg);
}

a.blank.white{
	color: #fff;
	background-image: url(../images/common/icon_blank_white.svg);
}


/*
***** [ PARTS ] *****

::: sample :::
<div class="achor">
	<ul>
		<li>
			<a href="#" class="e_btn_anchor">xxx</a>
		</li>
	</ul>
</div>

*/
.anchor{
	margin: 20px auto;
	padding: 0 15px;
	width: 100%;
	max-width: 1130px;

	ul{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: 0 -5px;
	}

	li{
    margin: 8px 0;
		padding: 0 5px;
		width: 50%;
	}
}
@media screen and (min-width:768px){
	.anchor{
		margin: 70px auto;

		ul{
			margin: 0 -35px;
		}

		li{
			padding: 0 35px;
			width: 390px;
		}
	}
}



/*
***** [ PARTS ] *****

::: sample :::
<table class="default">
	<thead>
		<tr>
			<th>xxxxxxx</th>
			<th>xxxxxxx</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>xxxxxxx</td>
			<td>xxxxxxx</td>
		</tr>
	</tbody>
</table>
*/
table.default{
	border-top: 1px solid #CCCCCC;
	border-bottom: 1px solid #C0DBEC;

	thead{

		th{
			padding: 9px 0;
			color: #FFF;
			font-size: 13px;
			font-weight: 700;
			text-align: center;
			line-height: 1.423;
			background-color: #00548B;

			&:not(:last-child){
				border-right: 1px solid #FFF;
			}
		}
	}

	tbody{

		th, td{
			padding: 9px 0;
			height: 40px;
			font-size: 13px;
			font-weight: 400;
			line-height: 1.423;
			text-align: center;
			background-color: #FFF;
			border-top: 1px solid #C0DBEC;

			&:not(:last-child){
				border-right: 1px solid #C0DBEC;
			}
		}

		th{
			font-size: 12px;
		}

		td{
			font-size: 13px;

			small{
				font-size: 10px;
			}

			sup{
				font-size: 11px;
			}
		}
	}
}
@media screen and (min-width:768px){
	table.default{

		thead{

			th{
				height: 70px;
				font-size: 17px;
			}
		}

		tbody{

			th, td{
				height: 70px;
			}

			th{
				font-size: 17px;
			}
	
			td{
				font-size: 17px;

				small{
					font-size: 13px;
				}
	
				sup{
					font-size: 13px;
				}
			}
		}
	}
}



/*
***** [ PARTS ] *****

::: sample :::
<div class="notice">
	<h4>ご注意</h4>
	<ul>
		<li>xxxxxx</li>
	</ul>
</div>
*/
.notice{

	h4{
		padding-left: 13px;
		color: #0072BC;
		font-size: 17px;
		font-weight: 500;
		line-height: 1.05;
		position: relative;

		&::before{
			content: "";
			display: block;
			width: 3px;
			height: 15px;
			background-color: #0072BC;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	ul{
		margin-top: 10px;
	}

	li{
		padding: 0 0 0 16px;
		position: relative;

		&::before{
			content: "";
			display: block;
			width: 8px;
			height: 8px;
			background-color: #C0DBEC;
			border-radius: 50%;
			position: absolute;
			left: 0;
			top: 10px;
		}

		& + li{
			margin-top: 3px;
		}
	}
}
@media screen and (min-width:768px){
	.notice{

		h4{
			padding-left: 23px;
			font-size: 20px;

			&::before{
				width: 7px;
				height: 24px;
			}
		}

		ul{
			margin-top: 16px;
		}

		li{
			padding: 0 0 0 23px;
			font-size: 16px;

			&::before{
				top: 15px;
			}
		}
	}
}



/*
***** [ PARTS ] *****

::: sample :::
<div class="notice">
	<h4>ご注意</h4>
	<ul>
		<li>xxxxxx</li>
	</ul>
</div>
*/
.note{
	margin-top: 10px;
	counter-reset: note_count;

	li{
		padding: 0 0 0 20px;
		font-size: 13px;
		line-height: 1.92;
		position: relative;

		&::before{
			content: "※";
			position: absolute;
			left: 0;
			top: 0;
		}
	}

	&.count{

		li{
			padding: 0 0 0 32px;

			&::before{
				counter-increment: note_count;
				content: "※" counter(note_count);
			}
		}
	}
}
@media screen and (min-width:768px){
	.note{
		margin-top: 25px;

		&.count{
			li{
				margin: 5px 0;
				padding: 0 0 0 24px;
			}
		}
	}
}


/*
***** [ PARTS ] *****

::: sample :::
<dl class="feature__list">
	<dt>特長<em>01</em></dt>
	<dd>USBタイプのICカードリーダー</dd>
</dl>

*/
.feature__list{
	display: flex;
	align-items: center;

	dt{
		padding-left: 2px;
		width: 50px;
		color: #0072BC;
		font-size: 13px;
		font-weight: 700;
		letter-spacing: 0.15em;
		line-height: 1.5;
		position: relative;

		&::after{
			content: "";
			display: block;
			width: 2px;
			height: 78%;
			border-right: 2px solid #0072BC;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}

		em{
			display: block;
			margin-top: 3px;
			margin-left: -2px;
			color: #0072BC;
			font-size: 25px;
			font-style: normal;
			line-height: 1;
		}
	}

	dd{
		padding-left: 20px;
		width: calc(100% - 50px);
		font-size: 16px;
		font-weight: 700;
		line-height: 1.68;
	}
}
@media screen and (min-width:768px){
	.feature__list{

		dt{
			padding-left: 2px;
			width: 68px;
		}

		dd{
			padding-left: 30px;
			font-size: 18px;
		}
	}
}






/*
***** [ PARTS ] *****

::: sample :::
<a href="xxx.jpg" class="js-modal-img"><img src="xxx.jpg" alt=""</a>

*/
.js-modal-img{
	position: relative;

	&::before{
		content: "";
		display: block;
		width: 30px;
		height: 30px;
		background-color: #fff;
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: 1;
	}

	&::after{
		content: "";
		display: block;
		width: 20px;
		height: 20px;
		background-image: url(../images/common/icon_scope.svg);
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: contain;
		position: absolute;
		right: 5px;
		bottom: 5px;
		z-index: 2;
	}
}
@media screen and (min-width: 768px) {
	.js-modal-img{
		position: relative;

		&::before{
			content: "";
			display: block;
			width: 36px;
			height: 36px;
			background-color: #fff;
		}
	
		&::after{
			width: 22px;
			height: 22px;
			right: 7px;
			bottom: 7px;
		}
	}
}



/* -----  MODAL WINDOW  ----- */
.modal-wrap{
  display:none;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
	z-index: 99;
}
.modal-inner{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 90%;
  height: 90%;
  max-width: 750px;
}
.js-modal-close{
  display: block;
  margin: 0 -5px 5px auto;
  width: 30px;
  height: 30px;
  background-image: url(../images/common/icon_close3.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  pointer-events: all;
  cursor: pointer;
  transition: all 0.6s ease;
}
.js-modal-close:hover{
  opacity: 0.8;
}
/* -----  MODAL WINDOW  ----- */



.fa-phone{
	display: inline-block;
	margin-right: 5px;
	width: 16px;
	height: 16px;
	background-image: url(../images/common/icon_phone.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
}

//-----------------------
.c-e72929{
	color: #E72929;
}

.c-0072bc{
	color: #0072BC;
}

.text-center{
	text-align: center;
}

.fs-13{
	font-size: 13px;
}