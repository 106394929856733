/************************
アニメーション
************************/

// scrollEffect -------------------------
.scrollEffect {
	transition: all 1500ms cubic-bezier(0.395, 0.085, 0.15, 0.86); /* custom */
	transition-timing-function: cubic-bezier(0.395, 0.085, 0.15, 0.86); /* custom */
}

// fadeIn -------------------------
.fadeIn {
	opacity: 0;

	&.view {
		opacity: 1;
	}
}

// fadeblur -------------------------
.fadeblur {
	transform: scale(1.1) translateY(20px);
	filter: blur(20px);
	opacity: 0;

	&.view {
		transform: scale(1) translateY(0);
		filter: blur(0);
		opacity: 1;
	}
}

// fadeIn -------------------------
.fadeIn {
	opacity: 0;

	&.view {
		opacity: 1;
	}
}

// fadeIn-left -------------------------
.fadeIn-left {
	opacity: 0;
	transform: translateX(-100px);

	&.view {
		opacity: 1;
		transform: translateX(0);
	}
}

// fadeIn-right -------------------------
.fadeIn-right {
	opacity: 0;
	transform: translateX(100px);

	&.view {
		opacity: 1;
		transform: translateX(0);
	}
}

// fadeIn-bottomUP -------------------------
.fadeIn-UP {
	opacity: 0;
	transform: translateY(100px);

	&.view {
		opacity: 1;
		transform: translateY(0);
	}
}
