@charset "UTF-8";
/************************
共通
************************/
/************************
リセット
************************/
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%; /* 10px */
}

body {
  width: 100%;
  color: #333;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", sans-serif;
  font-size: 14px;
  line-height: 1.92;
  letter-spacing: 0.05em;
  word-break: break-word;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

@media screen and (min-width: 768px) {
  body {
    font-size: 16px;
    line-height: 2.25;
    letter-spacing: 0.05em;
  }
}
/*----------------------------------------
	HTML5
----------------------------------------*/
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

/*----------------------------------------
	Text
----------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: 1.4;
}

pre {
  margin: 1.5em 25px;
  padding: 1em;
  overflow: scroll;
  line-height: 1.9;
}

blockquote {
  margin: 1.5em 1px;
  border: 5px solid #9f998f;
  color: #77471c;
  background-color: #f5f5f5;
  background-repeat: repeat-x;
  background-position: left bottom;
}

blockquote[title]::before {
  padding: 0.2em 10px 0.4em 10px;
  content: attr(title);
  display: block;
  color: #FFF;
  background-color: #9f998f;
  font-weight: bold;
}

blockquote[cite]::after {
  margin: 0.5em 10px;
  content: attr(cite);
  display: block;
  text-align: right;
  font-size: 85%;
}

blockquote * {
  color: #333 !important;
}

em {
  color: #000;
}

pre {
  background: #eee;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}

pre em {
  font-weight: bold;
  background-image: none;
}

q {
  text-decoration: underline;
}

b,
strong {
  font-weight: bold;
}

cite {
  display: block;
  text-align: right;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

kbd {
  background-color: #f5f5f5;
  text-transform: uppercase;
  padding: 0 0.4em;
  border: 1px solid #6a8eaa;
}

del {
  text-decoration: line-through;
}

ins {
  border-top: 10px solid #3cadd6;
  border-bottom: 10px solid #3cadd6;
  border: 7px solid #3cadd6;
  margin: 2em 1px;
  padding: 1px 0;
  display: block;
  text-decoration: none;
}

mark {
  background: #fff9c0;
  text-decoration: none;
}

ins * {
  color: #666 !important;
  background: #fff9c0;
  text-decoration: none;
}

abbr,
acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}

address {
  margin: 0 0 1.5em;
}

sup,
sub {
  font-size: 75%;
  height: 0;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  bottom: 1ex;
}

sub {
  top: 0.5ex;
}

small {
  font-size: 75%;
}

big {
  font-size: 125%;
}

hr {
  background-color: #ccc;
  border: 0;
  height: 1px;
  margin-bottom: 1.5em;
}

/*----------------------------------------
	List
----------------------------------------*/
ul,
ol,
li,
dl,
dt,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
  font-weight: normal;
}

/*----------------------------------------
	Image
----------------------------------------*/
figure {
  margin: 0;
  line-height: 1;
}

img {
  height: auto; /* Make sure images are scaled correctly. */
  max-width: 100%;
  border: none;
  vertical-align: baseline;
  -webkit-backface-visibility: hidden;
  image-rendering: -webkit-optimize-contrast;
}

/*----------------------------------------
	Anchor
----------------------------------------*/
a {
  color: #0072BC;
  text-decoration: underline;
  transition: opacity 0.3s ease;
}

a:hover,
a:focus,
a:active {
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  opacity: 0.6;
}

@media screen and (min-width: 768px) {
  a[href^="tel:"] {
    pointer-events: none;
    text-decoration: none;
  }
}
/*----------------------------------------
	P
----------------------------------------*/
p {
  margin: 0;
  padding: 0;
}

/*----------------------------------------
	Table
----------------------------------------*/
table {
  margin: 0;
  width: 100%;
  border-collapse: collapse;
}

/*----------------------------------------
	Form
----------------------------------------*/
fieldset,
legend {
  border: none;
}

input[type=text],
textarea {
  padding: 0.2em;
  border: 1px solid #d1d1d1;
  background-color: #FFF;
}

input {
  line-height: 1.2;
}

select,
input,
textarea,
button {
  vertical-align: middle;
  position: relative;
  margin-right: 3px;
  top: 0;
}

input[type=checkbox],
input[type=radio] {
  border: none;
  top: -1px;
  margin-right: 2px;
}

input[type=text]:focus,
textarea:focus {
  border: 0;
  background: none;
}

input.button {
  padding: 0;
  border: none;
  background-image: none;
  vertical-align: top;
}

/* iOSでのデフォルトスタイルをリセット */
input[type=submit],
input[type=button] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
}

input[type=submit]::-webkit-search-decoration,
input[type=button]::-webkit-search-decoration {
  display: none;
}

input[type=submit]:focus,
input[type=button]:focus {
  outline-offset: -2px;
}

/*
***** [ PARTS ] *****

::: sample :::
<p class="contactBtn"><a href="#">お問い合わせ</a></p>

*/
.contactBtn {
  text-align: center;
}
.contactBtn a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 300px;
  height: 55px;
  color: #333333;
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: #ffe09f;
  background: -moz-linear-gradient(left, #ffe09f 0%, #ffc447 50%, #ffc447 50%, #ffad00 100%);
  background: -webkit-linear-gradient(left, #ffe09f 0%, #ffc447 50%, #ffc447 50%, #ffad00 100%);
  background: linear-gradient(to right, #ffe09f 0%, #ffc447 50%, #ffc447 50%, #ffad00 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffe09f", endColorstr="#ffad00",GradientType=1 );
  background-color: #FFE09F;
  border-radius: 6px;
  position: relative;
  transition: all 0.3s ease;
}
.contactBtn a::after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background-image: url(../images/common/icon_arw_btn1.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  position: absolute;
  right: 20px;
  top: 50%;
  transition: all 0.3s ease;
  transform: translateY(-50%);
}
.contactBtn a:hover {
  background: #ffe09f;
  background-color: #FFE09F;
  opacity: 1;
}
.contactBtn a:hover::after {
  right: 15px;
}

/*
***** [ PARTS ] *****

::: sample :::
<p id="pagetop"><a href="#"><span>ページトップ</span></a></p>

*/
#pagetop {
  display: block;
  width: 50px;
  height: 50px;
  position: fixed;
  right: 10px;
  bottom: 70px;
  z-index: 6;
  transition: all 0.3s ease;
}
#pagetop a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #FFF;
  text-decoration: none;
  background-color: #0082D6;
  background-image: url(../images/common/page_top.svg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 37px auto;
  border-radius: 50%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.8s ease;
}
#pagetop a.view {
  visibility: visible;
  opacity: 1;
}
#pagetop a:hover {
  background-color: #008EEB;
  opacity: 1;
}

@media screen and (min-width: 768px) {
  #pagetop {
    width: 70px;
    height: 70px;
  }
  #pagetop a {
    background-size: 50px auto;
  }
}
/*
***** [ PARTS ] *****

::: sample :::
<a href="#" class="e_btn">xxxxxxxxxx</a>

*/
.e_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  width: 250px;
  height: 50px;
  color: #FFF;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  text-decoration: none;
  background: #0082d6;
  background: -moz-linear-gradient(left, #0082d6 0%, #0072BC 50%, #00548b 100%);
  background: -webkit-linear-gradient(left, #0082d6 0%, #0072BC 50%, #00548b 100%);
  background: linear-gradient(to right, #0082d6 0%, #0072BC 50%, #00548b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#0082d6", endColorstr="#00548b",GradientType=1 );
  border: 1px solid #FFF;
  border-radius: 5px;
  position: relative;
}
.e_btn::after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background-image: url(../images/common/icon_arw_btn2.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  position: absolute;
  right: 20px;
  top: 50%;
  transition: all 0.3s ease;
  transform: translateY(-50%);
}
.e_btn:hover {
  opacity: 1;
  background: #008EEB;
  border: 1px solid #008EEB;
}
.e_btn:hover::after {
  right: 15px;
}
.e_btn.type01 {
  width: 250px;
  height: 50px;
}
.e_btn.type02 {
  width: 300px;
  height: 50px;
}
.e_btn.type03 {
  width: 100%;
  height: 45px;
  font-size: 13px;
  background: #0072BC;
}
.e_btn.type03::after {
  width: 14px;
  height: 14px;
  right: 10px;
}
.e_btn.type03:hover {
  opacity: 1;
  background: #008EEB;
  border: 1px solid #008EEB;
}
.e_btn.type03:hover::after {
  right: 5px;
}
.e_btn.type_blank::after {
  width: 20px;
  height: 13px;
  background-image: url(../images/common/icon_blank_white.svg);
}

/*
***** [ PARTS ] *****

::: sample :::
<a href="#" class="p_btn">xxxxxxxxxx</a>

*/
.p_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 320px;
  height: 70px;
  margin: 10px;
  color: #0082d6;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  text-decoration: none;
  background: #fff;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#0082d6", endColorstr="#00548b",GradientType=1 );
  border: 1px solid #FFF;
  border-radius: 5px;
  position: relative;
}
.p_btn::after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background-image: url(../images/common/icon_pdf_btn.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  position: absolute;
  right: 20px;
  top: 50%;
  transition: all 0.3s ease;
  transform: translateY(-50%);
}
.p_btn:hover {
  opacity: 1;
}
.p_btn:hover::after {
  right: 15px;
}
.p_btn.type01 {
  width: 250px;
  height: 50px;
}

@media screen and (min-width: 992px) {
  .e_btn {
    width: 408px;
    height: 72px;
    font-size: 20px;
  }
  .e_btn::after {
    width: 24px;
    height: 24px;
    right: 30px;
  }
  .e_btn:hover::after {
    right: 25px;
  }
  .e_btn.type01 {
    width: 408px;
    height: 72px;
  }
  .e_btn.type03 {
    width: 100%;
    height: 62px;
    font-size: 18px;
  }
  .e_btn.type03::after {
    width: 20px;
    height: 20px;
    right: 28px;
  }
  .e_btn.type03:hover::after {
    right: 23px;
  }
  .e_btn.type04 {
    width: 100%;
    height: 62px;
    font-size: 18px;
    background-image: url(../images/common/icon_pdf_btn.svg);
  }
  .e_btn.type04::after {
    width: 20px;
    height: 20px;
    right: 28px;
  }
  .e_btn.type04:hover::after {
    right: 23px;
  }
}
/*
***** [ PARTS ] *****

::: sample :::
<a href="#" class="e_btn_arw">xxxxxxxxxx</a>

*/
.e_btn_arw {
  display: block;
  padding-right: 20px;
  position: relative;
}
.e_btn_arw::after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background-image: url(../images/common/icon_arw_btn3.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  position: absolute;
  right: 5px;
  top: 50%;
  transition: all 0.3s ease;
  transform: translateY(-50%);
}
.e_btn_arw:hover {
  color: #0082D6;
  background-color: #EFF9FF;
}
.e_btn_arw:hover::after {
  right: 0;
}

@media screen and (min-width: 992px) {
  .e_btn_arw::after {
    width: 24px;
    height: 24px;
  }
}
/*
***** [ PARTS ] *****

::: sample :::
<a href="#" class="e_btn_arw_2">xxxxxxxxxx</a>

*/
.e_btn_arw_2 {
  display: block;
  padding-right: 33px;
  position: relative;
}
.e_btn_arw_2::after {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  background-image: url(../images/common/icon_arw_btn3.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  position: absolute;
  right: 5px;
  top: 50%;
  transition: all 0.3s ease;
  transform: translateY(-50%);
}
.e_btn_arw_2:hover {
  color: #0082D6;
}
.e_btn_arw_2:hover::after {
  right: 0;
}

/*
***** [ PARTS ] *****

::: sample :::
<a href="#" class="e_btn_anchor">xxxxxxxxxx</a>

*/
.e_btn_anchor {
  display: flex;
  align-items: center;
  padding: 0 45px 0 0;
  width: 100%;
  height: 56px;
  color: #333;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.57;
  letter-spacing: 0;
  text-decoration: none;
  border-bottom: 1px solid #c0dbec;
  position: relative;
}
.e_btn_anchor::after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background-image: url(../images/common/icon_arw_btn3.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  position: absolute;
  right: 5px;
  top: 50%;
  transition: all 0.3s ease;
  transform: translateY(-50%) rotate(90deg);
}
.e_btn_anchor:hover {
  color: #0082D6;
  background-color: #EFF9FF;
  opacity: 1;
}
.e_btn_anchor:hover::after {
  top: 54%;
}

@media screen and (min-width: 992px) {
  .e_btn_anchor {
    padding: 0 35px 0 5px;
    font-size: 18px;
    letter-spacing: 0.05em;
  }
  .e_btn_anchor::after {
    width: 24px;
    height: 24px;
    right: 10px;
    top: 47%;
  }
}
/*
***** [ PARTS ] *****

::: sample :::
<a href="#" class="e_btn_back">xxxxxxxxxx</a>

*/
.e_btn_back {
  display: inline-block;
  padding: 11px 0 11px 23px;
  width: 100%;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
  text-decoration: none;
  position: relative;
}
.e_btn_back::after {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  background-image: url(../images/common/icon_arw_btn3.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  position: absolute;
  left: 0;
  top: 50%;
  transition: all 0.3s ease;
  transform: translateY(-50%) rotate(180deg);
}

@media screen and (min-width: 992px) {
  .e_btn_back {
    padding: 11px 0 11px 40px;
    font-size: 16px;
  }
  .e_btn_back::after {
    width: 20px;
    height: 20px;
    left: 10px;
  }
  .e_btn_back:hover {
    opacity: 1;
  }
  .e_btn_back:hover::after {
    left: 0;
  }
}
.e_btn_no_arw {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  height: 50px;
  max-width: 300px;
  color: #FFF;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.15em;
  text-decoration: none;
  background: #0082d6;
  background: -moz-linear-gradient(left, #0082d6 0%, #0072BC 50%, #00548b 100%);
  background: -webkit-linear-gradient(left, #0082d6 0%, #0072BC 50%, #00548b 100%);
  background: linear-gradient(to right, #0082d6 0%, #0072BC 50%, #00548b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#0082d6", endColorstr="#00548b",GradientType=1 );
  border: 1px solid #FFF;
  border-radius: 5px;
}
.e_btn_no_arw:hover {
  opacity: 1;
  background: #008EEB;
  border: 1px solid #008EEB;
}

@media screen and (min-width: 992px) {
  .e_btn_no_arw {
    height: 72px;
    max-width: 408px;
    font-size: 24px;
    letter-spacing: 0.05em;
  }
}
/*
***** [ PARTS ] *****

::: sample :::
<span class="marker">xxx</span>

*/
.marker {
  background: linear-gradient(transparent 70%, #FFF2D1 30%);
}

/*
***** [ PARTS ] *****

::: sample :::
<a href="#" target="_blank" class="blank">xxx</a>

*/
a.blank {
  display: inline;
  padding-right: 30px;
  color: #0072BC;
  background-image: url(../images/common/icon_blank_blue.svg);
  background-repeat: no-repeat;
  background-position: 98% 50%;
  background-size: 20px 13px;
}

a.blank.red {
  color: #E72929;
  background-image: url(../images/common/icon_blank_red.svg);
}

a.blank.white {
  color: #fff;
  background-image: url(../images/common/icon_blank_white.svg);
}

/*
***** [ PARTS ] *****

::: sample :::
<div class="achor">
	<ul>
		<li>
			<a href="#" class="e_btn_anchor">xxx</a>
		</li>
	</ul>
</div>

*/
.anchor {
  margin: 20px auto;
  padding: 0 15px;
  width: 100%;
  max-width: 1130px;
}
.anchor ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -5px;
}
.anchor li {
  margin: 8px 0;
  padding: 0 5px;
  width: 50%;
}

@media screen and (min-width: 768px) {
  .anchor {
    margin: 70px auto;
  }
  .anchor ul {
    margin: 0 -35px;
  }
  .anchor li {
    padding: 0 35px;
    width: 390px;
  }
}
/*
***** [ PARTS ] *****

::: sample :::
<table class="default">
	<thead>
		<tr>
			<th>xxxxxxx</th>
			<th>xxxxxxx</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>xxxxxxx</td>
			<td>xxxxxxx</td>
		</tr>
	</tbody>
</table>
*/
table.default {
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #C0DBEC;
}
table.default thead th {
  padding: 9px 0;
  color: #FFF;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  line-height: 1.423;
  background-color: #00548B;
}
table.default thead th:not(:last-child) {
  border-right: 1px solid #FFF;
}
table.default tbody th, table.default tbody td {
  padding: 9px 0;
  height: 40px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.423;
  text-align: center;
  background-color: #FFF;
  border-top: 1px solid #C0DBEC;
}
table.default tbody th:not(:last-child), table.default tbody td:not(:last-child) {
  border-right: 1px solid #C0DBEC;
}
table.default tbody th {
  font-size: 12px;
}
table.default tbody td {
  font-size: 13px;
}
table.default tbody td small {
  font-size: 10px;
}
table.default tbody td sup {
  font-size: 11px;
}

@media screen and (min-width: 768px) {
  table.default thead th {
    height: 70px;
    font-size: 17px;
  }
  table.default tbody th, table.default tbody td {
    height: 70px;
  }
  table.default tbody th {
    font-size: 17px;
  }
  table.default tbody td {
    font-size: 17px;
  }
  table.default tbody td small {
    font-size: 13px;
  }
  table.default tbody td sup {
    font-size: 13px;
  }
}
/*
***** [ PARTS ] *****

::: sample :::
<div class="notice">
	<h4>ご注意</h4>
	<ul>
		<li>xxxxxx</li>
	</ul>
</div>
*/
.notice h4 {
  padding-left: 13px;
  color: #0072BC;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.05;
  position: relative;
}
.notice h4::before {
  content: "";
  display: block;
  width: 3px;
  height: 15px;
  background-color: #0072BC;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.notice ul {
  margin-top: 10px;
}
.notice li {
  padding: 0 0 0 16px;
  position: relative;
}
.notice li::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background-color: #C0DBEC;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 10px;
}
.notice li + li {
  margin-top: 3px;
}

@media screen and (min-width: 768px) {
  .notice h4 {
    padding-left: 23px;
    font-size: 20px;
  }
  .notice h4::before {
    width: 7px;
    height: 24px;
  }
  .notice ul {
    margin-top: 16px;
  }
  .notice li {
    padding: 0 0 0 23px;
    font-size: 16px;
  }
  .notice li::before {
    top: 15px;
  }
}
/*
***** [ PARTS ] *****

::: sample :::
<div class="notice">
	<h4>ご注意</h4>
	<ul>
		<li>xxxxxx</li>
	</ul>
</div>
*/
.note {
  margin-top: 10px;
  counter-reset: note_count;
}
.note li {
  padding: 0 0 0 20px;
  font-size: 13px;
  line-height: 1.92;
  position: relative;
}
.note li::before {
  content: "※";
  position: absolute;
  left: 0;
  top: 0;
}
.note.count li {
  padding: 0 0 0 32px;
}
.note.count li::before {
  counter-increment: note_count;
  content: "※" counter(note_count);
}

@media screen and (min-width: 768px) {
  .note {
    margin-top: 25px;
  }
  .note.count li {
    margin: 5px 0;
    padding: 0 0 0 24px;
  }
}
/*
***** [ PARTS ] *****

::: sample :::
<dl class="feature__list">
	<dt>特長<em>01</em></dt>
	<dd>USBタイプのICカードリーダー</dd>
</dl>

*/
.feature__list {
  display: flex;
  align-items: center;
}
.feature__list dt {
  padding-left: 2px;
  width: 50px;
  color: #0072BC;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.15em;
  line-height: 1.5;
  position: relative;
}
.feature__list dt::after {
  content: "";
  display: block;
  width: 2px;
  height: 78%;
  border-right: 2px solid #0072BC;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.feature__list dt em {
  display: block;
  margin-top: 3px;
  margin-left: -2px;
  color: #0072BC;
  font-size: 25px;
  font-style: normal;
  line-height: 1;
}
.feature__list dd {
  padding-left: 20px;
  width: calc(100% - 50px);
  font-size: 16px;
  font-weight: 700;
  line-height: 1.68;
}

@media screen and (min-width: 768px) {
  .feature__list dt {
    padding-left: 2px;
    width: 68px;
  }
  .feature__list dd {
    padding-left: 30px;
    font-size: 18px;
  }
}
/*
***** [ PARTS ] *****

::: sample :::
<a href="xxx.jpg" class="js-modal-img"><img src="xxx.jpg" alt=""</a>

*/
.js-modal-img {
  position: relative;
}
.js-modal-img::before {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  background-color: #fff;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.js-modal-img::after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background-image: url(../images/common/icon_scope.svg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  position: absolute;
  right: 5px;
  bottom: 5px;
  z-index: 2;
}

@media screen and (min-width: 768px) {
  .js-modal-img {
    position: relative;
  }
  .js-modal-img::before {
    content: "";
    display: block;
    width: 36px;
    height: 36px;
    background-color: #fff;
  }
  .js-modal-img::after {
    width: 22px;
    height: 22px;
    right: 7px;
    bottom: 7px;
  }
}
/* -----  MODAL WINDOW  ----- */
.modal-wrap {
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}

.modal-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 90%;
  height: 90%;
  max-width: 750px;
}

.js-modal-close {
  display: block;
  margin: 0 -5px 5px auto;
  width: 30px;
  height: 30px;
  background-image: url(../images/common/icon_close3.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  pointer-events: all;
  cursor: pointer;
  transition: all 0.6s ease;
}

.js-modal-close:hover {
  opacity: 0.8;
}

/* -----  MODAL WINDOW  ----- */
.fa-phone {
  display: inline-block;
  margin-right: 5px;
  width: 16px;
  height: 16px;
  background-image: url(../images/common/icon_phone.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
}

.c-e72929 {
  color: #E72929;
}

.c-0072bc {
  color: #0072BC;
}

.text-center {
  text-align: center;
}

.fs-13 {
  font-size: 13px;
}

/************************
共通　ヘッダー
************************/
.site-header {
  display: flex;
  align-items: center;
  padding: 0 15px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  transition: all 0.3s ease;
}
@media screen and (max-width: 991px) {
  .site-header {
    height: 50px;
  }
}
@media screen and (min-width: 992px) {
  .site-header {
    height: 100px;
  }
}
@media screen and (min-width: 992px) {
  .site-header__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    height: 100px;
    max-width: 1100px;
    position: relative;
    z-index: 2;
  }
}
.site-header.fixed {
  background-color: rgba(255, 255, 255, 0.9);
}

@media screen and (max-width: 991px) {
  .header__logo {
    width: 125px;
  }
}
@media screen and (min-width: 992px) {
  .header__logo {
    width: 230px;
  }
}
.header__logo a {
  display: block;
  line-height: 1;
}
@media screen and (max-width: 991px) {
  .header__gnavi {
    padding: 40px 15px 100px;
    width: 100vw;
    color: #fff;
    background-color: #00548b;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 1;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
  .header__gnavi__msg {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
  }
  .header__gnavi .contactBtn {
    margin-top: 10px;
  }
  .header__gnavi nav {
    margin-top: 20px;
    padding-bottom: 80px;
    width: 100%;
    height: 72vh;
    overflow: auto;
    -ms-overflow-style: none;
  }
  .header__gnavi nav::-webkit-scrollbar {
    display: none;
  }
  .header__gnavi ul,
  .header__gnavi li {
    margin: 0;
    padding: 0;
  }
  .header__gnavi ul.menu a,
  .header__gnavi ul.menu span {
    display: block;
    color: #fff;
    font-size: 17px;
    font-weight: 700;
  }
  .header__gnavi ul.menu a {
    text-decoration: none;
  }
  .header__gnavi ul.menu > li {
    padding: 16px 0;
    border-bottom: 1px solid #fff;
  }
  .header__gnavi ul.menu > li > span {
    position: relative;
  }
  .header__gnavi ul.menu > li > span::after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background-image: url(../images/common/icon_open.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    position: absolute;
    right: 0;
    top: 50%;
    transition: all 0.3s ease;
    transform: translateY(-50%);
  }
  .header__gnavi ul.menu > li > span.open::after {
    background-image: url(../images/common/icon_close.png);
  }
  .header__gnavi ul.menu > li em {
    color: #fff;
    font-size: 17px;
    font-weight: 700;
    font-style: normal;
  }
  .header__gnavi ul.sub {
    display: none;
    padding: 8px 0 0;
  }
  .header__gnavi ul.sub > li {
    padding: 6px 0;
  }
  .header__gnavi ul.sub > li > a {
    padding: 0 10px;
    color: #ceebff;
    font-size: 14px;
    font-weight: 400;
  }
  .header__gnavi ul.sub > li > a > span {
    color: #ceebff;
    font-size: 14px;
    font-weight: 400;
  }
  .header__gnavi ul.sub > li em {
    color: #ceebff;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
  }
  .header__gnavi ul.group > li {
    padding: 6px 0;
  }
  .header__gnavi ul.group > li a {
    padding: 0 10px;
    color: #ceebff;
    font-size: 14px;
    font-weight: 400;
  }
}
@media screen and (min-width: 992px) {
  .header__gnavi {
    padding: 48px 0 13px;
  }
  .header__gnavi .contactBtn {
    position: absolute;
    right: 0;
    top: 0;
  }
  .header__gnavi .contactBtn a {
    padding-right: 20px;
    width: 195px;
    height: 40px;
    font-size: 17px;
    border-radius: 0 0 6px 6px;
  }
  .header__gnavi .contactBtn a::after {
    right: 25px;
  }
  .header__gnavi .contactBtn a:hover::after {
    right: 20px;
  }
  .header__gnavi nav {
    margin: 0;
    padding: 0;
  }
  .header__gnavi ul,
  .header__gnavi li {
    margin: 0;
    padding: 0;
  }
  .header__gnavi ul.menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 -2px;
  }
  .header__gnavi ul.menu a,
  .header__gnavi ul.menu span {
    display: block;
    font-size: 10px;
    font-weight: 500;
  }
  .header__gnavi ul.menu a {
    color: #333;
    text-decoration: none;
  }
  .header__gnavi ul.menu a:hover {
    color: #0071bc;
    opacity: 1;
  }
  .header__gnavi ul.menu > li {
    padding: 0 2px;
  }
  .header__gnavi ul.menu > li > a, .header__gnavi ul.menu > li > span {
    padding-bottom: 5px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .header__gnavi ul.menu > li > a::after, .header__gnavi ul.menu > li > span::after {
    content: "";
    display: block;
    margin-left: -6px;
    width: 12px;
    height: 8px;
    background-image: url(../images/common/icon_arw_gm.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    position: absolute;
    left: 50%;
    bottom: 0;
    transition: all 0.3s ease;
  }
  .header__gnavi ul.menu > li > a:hover, .header__gnavi ul.menu > li > span:hover {
    color: #0071bc;
    opacity: 1;
  }
  .header__gnavi ul.menu > li > a:hover::after, .header__gnavi ul.menu > li > span:hover::after {
    transform: translateY(5px);
  }
  .header__gnavi ul.menu > li > a.no-arrow::after, .header__gnavi ul.menu > li > span.no-arrow::after {
    display: none;
  }
  .header__gnavi ul.sub {
    display: flex !important;
    width: calc(100vw - 30px);
    max-width: 1100px;
    position: absolute;
    top: 100%;
    left: 0;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease;
  }
  .header__gnavi ul.sub::before, .header__gnavi ul.sub::after {
    content: "";
    display: block;
    width: 70vw;
    height: 100%;
    position: absolute;
    top: 0;
  }
  .header__gnavi ul.sub::before {
    background-color: #004674;
    right: 50%;
  }
  .header__gnavi ul.sub::after {
    background-color: #fff;
    left: 50%;
  }
  .header__gnavi ul.sub > li {
    display: flex;
    width: 100%;
    color: #fff;
    background-color: #004674;
    position: relative;
    z-index: 1;
  }
  .header__gnavi ul.sub > li > a {
    display: flex;
    padding: 40px 0 0;
    width: 250px;
    color: #fff;
  }
  .header__gnavi ul.sub > li > a:hover {
    color: #fff;
    opacity: 0.6;
  }
  .header__gnavi ul.sub > li > a > span {
    display: inline-block;
    padding-right: 40px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.05em;
    position: relative;
  }
  .header__gnavi ul.sub > li > a > span::after {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    background-image: url(../images/common/icon_arw_btn2.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    position: absolute;
    right: 0;
    top: -5px;
  }
  .header__gnavi ul.group {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 60px;
    width: calc(100% - 250px);
    background-color: #fff;
    position: relative;
    z-index: 1;
  }
  .header__gnavi ul.group > li {
    margin: 10px 0;
    width: 50%;
  }
  .header__gnavi ul.group > li > a {
    display: inline-block;
    padding-right: 30px;
    color: #333;
    font-size: 12px;
    font-weight: 500;
    position: relative;
  }
  .header__gnavi ul.group > li > a::after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background-image: url(../images/common/icon_arw_btn3.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .header__gnavi ul.group > li > a:hover {
    color: #333;
    opacity: 0.6;
  }
  .header__gnavi ul.group + .js-closeBtn {
    display: block;
    width: 20px;
    height: 20px;
    background-image: url(../images/common/icon_close2.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    position: absolute;
    right: 20px;
    top: 30px;
    z-index: 1;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  .header__gnavi ul.group + .js-closeBtn:hover {
    opacity: 6;
  }
}
@media screen and (min-width: 992px) {
  .header__gnavi ul.menu {
    margin: 0 -12px;
    width: max-content;
  }
  .header__gnavi ul.menu a,
  .header__gnavi ul.menu span {
    font-size: 13px;
  }
  .header__gnavi ul.menu > li {
    padding: 0 12px;
  }
  .header__gnavi ul.sub > li > a {
    padding: 50px 0 0;
    width: 310px;
  }
  .header__gnavi ul.sub > li > a > span {
    font-size: 22px;
  }
  .header__gnavi ul.sub > li > a > span::after {
    top: 0;
  }
  .header__gnavi ul.group {
    padding: 35px 75px 35px 65px;
    width: calc(100% - 290px);
  }
  .header__gnavi ul.group > li > a {
    font-size: 16px;
  }
  .header__gnavi ul.group + .js-closeBtn {
    top: 53px;
  }
}
@media screen and (min-width: 1200px) {
  .header__gnavi {
    padding: 48px 0 13px;
  }
  .header__gnavi ul.menu {
    margin: 0 -17px;
    width: max-content;
  }
  .header__gnavi ul.menu a,
  .header__gnavi ul.menu span {
    font-size: 15px;
  }
  .header__gnavi ul.menu > li {
    padding: 0 17px;
  }
}

@media screen and (max-width: 991px) {
  .menu-open .header__gnavi {
    transition: all 0.3s ease;
    opacity: 1;
    pointer-events: all;
    visibility: visible;
  }
}
@media screen and (min-width: 992px) {
  .js-gmenuBtn.open + ul.sub {
    pointer-events: all;
    visibility: visible;
    opacity: 1;
  }
}
@media screen and (min-width: 992px) {
  .overlay {
    display: block;
    width: 100vw;
    height: calc(100vh - 100px);
    background-color: rgba(51, 51, 51, 0.4);
    position: fixed;
    top: 100px;
    left: 0;
    z-index: 1;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
  }
  .overlay.open {
    pointer-events: all;
    visibility: visible;
    opacity: 1;
  }
}
body.fixed {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
}

/************************
共通　フッター
************************/
.site-footer {
  border-top: 1px solid #C0DBEC;
}
.site-footer__inner.footer1 {
  background-color: #FFF;
}
@media screen and (min-width: 768px) {
  .site-footer__inner.footer1 {
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
    max-width: 1140px;
  }
}
.site-footer__inner.footer2 {
  color: #FFF;
  background-color: #004674;
}

@media screen and (max-width: 991px) {
  .footer__gnavi {
    padding: 38px 15px;
  }
  .footer__gnavi__msg {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
  }
  .footer__gnavi .contactBtn {
    margin-top: 10px;
  }
  .footer__gnavi nav {
    margin-top: 25px;
    width: 100%;
  }
  .footer__gnavi ul, .footer__gnavi li {
    margin: 0;
    padding: 0;
  }
  .footer__gnavi ul.menu a, .footer__gnavi ul.menu span {
    display: block;
    color: #0072BC;
    font-size: 15px;
    font-weight: 700;
  }
  .footer__gnavi ul.menu a {
    text-decoration: none;
  }
  .footer__gnavi ul.menu > li {
    padding: 10px 0;
    border-bottom: 1px solid #C0DBEC;
  }
  .footer__gnavi ul.menu > li span {
    position: relative;
  }
  .footer__gnavi ul.menu > li span::after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background-image: url(../images/common/icon_open2.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    position: absolute;
    right: 0;
    top: 50%;
    transition: all 0.3s ease;
    transform: translateY(-50%);
  }
  .footer__gnavi ul.menu > li span.open::after {
    background-image: url(../images/common/icon_close2.png);
  }
  .footer__gnavi ul.sub {
    display: none;
    margin-top: 10px;
  }
  .footer__gnavi ul.sub > li {
    padding: 6px 0;
  }
  .footer__gnavi ul.sub > li a {
    padding: 0 10px;
    color: #333;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
  }
  .footer__gnavi ul.group > li {
    padding: 6px 0;
  }
  .footer__gnavi ul.group > li a {
    padding: 0 10px;
    color: #333;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
  }
}
@media screen and (max-width: 767px) {
  .footer__gnavi__tool {
    display: block;
    margin-top: 40px;
  }
  .footer__gnavi__tool a {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 90px;
    max-width: 300px;
    text-indent: -9999px;
    overflow: hidden;
    background-image: url(../images/common/btn_tool.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    transition: all 0.3s ease;
    position: relative;
  }
  .footer__gnavi__tool a::after {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    background-image: url(../images/common/icon_arw_btn2.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    position: absolute;
    right: 30px;
    top: 46%;
    transition: all 0.3s ease;
    transform: translateY(-50%);
  }
  .footer__gnavi__tool a:hover {
    background-image: url(../images/common/btn_tool_on.png);
    opacity: 1;
  }
  .footer__gnavi__tool a:hover::after {
    right: 25px;
  }
}
@media screen and (min-width: 768px) {
  .footer__gnavi__tool {
    display: block;
    margin-top: 40px;
  }
  .footer__gnavi__tool a {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 90px;
    max-width: 300px;
    text-indent: -9999px;
    overflow: hidden;
    background-image: url(../images/common/btn_tool.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    transition: all 0.3s ease;
    position: relative;
  }
  .footer__gnavi__tool a::after {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    background-image: url(../images/common/icon_arw_btn2.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    position: absolute;
    right: 30px;
    top: 46%;
    transition: all 0.3s ease;
    transform: translateY(-50%);
  }
  .footer__gnavi__tool a:hover {
    background-image: url(../images/common/btn_tool_on.png);
    opacity: 1;
  }
  .footer__gnavi__tool a:hover::after {
    right: 25px;
  }
}
@media screen and (min-width: 992px) {
  .footer__gnavi {
    padding: 40px 0 33px;
  }
  .footer__gnavi__info {
    margin: 0 auto;
    width: 408px;
    text-align: center;
  }
  .footer__gnavi__msg {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
  }
  .footer__gnavi .contactBtn {
    margin-top: 15px;
  }
  .footer__gnavi .contactBtn a {
    width: 408px;
    height: 90px;
    font-size: 24px;
  }
  .footer__gnavi .contactBtn a::after {
    width: 24px;
    height: 24px;
    right: 30px;
  }
  .footer__gnavi .contactBtn a:hover::after {
    right: 25px;
  }
  .footer__gnavi__tool a {
    max-width: 100%;
  }
  .footer__gnavi nav {
    display: flex;
    margin-top: 40px;
    width: 100%;
  }
  .footer__gnavi ul, .footer__gnavi li {
    margin: 0;
    padding: 0;
  }
  .footer__gnavi ul.menu {
    width: 50%;
  }
  .footer__gnavi ul.menu:nth-of-type(2) {
    margin-top: 48px;
  }
  .footer__gnavi ul.menu a, .footer__gnavi ul.menu span {
    display: block;
    padding: 0 0 0 35px;
    color: #0072BC;
    font-size: 16px;
    font-weight: 700;
  }
  .footer__gnavi ul.menu a {
    text-decoration: none;
  }
  .footer__gnavi ul.menu > li {
    margin: 15px 0;
  }
  .footer__gnavi ul.menu > li > a, .footer__gnavi ul.menu > li > span {
    position: relative;
  }
  .footer__gnavi ul.menu > li > a::before, .footer__gnavi ul.menu > li > span::before {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    background-image: url(../images/common/icon_arw_btn3.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    position: absolute;
    left: 0;
    top: 50%;
    transition: all 0.3s ease;
    transform: translateY(-50%);
  }
  .footer__gnavi ul.sub {
    display: block !important;
  }
  .footer__gnavi ul.sub.sp {
    display: none !important;
  }
  .footer__gnavi ul.group > li {
    padding: 4px 0;
  }
  .footer__gnavi ul.group > li a {
    padding: 0 35px;
    color: #333;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.96;
  }
}
@media screen and (min-width: 1100px) {
  .footer__gnavi {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .footer__gnavi__info {
    margin: 0;
    padding: 17px 0 0;
  }
  .footer__gnavi nav {
    margin: 0;
  }
}

@media screen and (max-width: 991px) {
  .footer__nav2 {
    padding: 15px;
  }
  .footer__nav2 ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  .footer__nav2 ul li {
    padding: 5px 10px;
  }
  .footer__nav2 ul a {
    display: block;
    color: #E3E3E3;
    font-size: 11px;
    letter-spacing: 0.05em;
  }
  .footer__nav2 ul a[target=_blank] {
    padding-right: 20px;
  }
  .footer__nav2 ul a[target=_blank] {
    position: relative;
  }
  .footer__nav2 ul a[target=_blank]::after {
    content: "";
    display: block;
    width: 15px;
    height: 10px;
    background-image: url(../images/common/icon_blank.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    position: absolute;
    right: 0;
    top: 50%;
    transition: all 0.3s ease;
    transform: translateY(-50%);
  }
  .footer__nav2 .copyright {
    margin-top: 5px;
    color: #C0DBEC;
    font-size: 11px;
    letter-spacing: 0;
  }
}
@media screen and (min-width: 992px) {
  .footer__nav2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 16px 20px;
    width: 100%;
    max-width: 1140px;
  }
  .footer__nav2 ul {
    display: flex;
    gap: 0 35px;
  }
  .footer__nav2 ul a {
    display: block;
    color: #E3E3E3;
    font-size: 13px;
    text-decoration: underline;
  }
  .footer__nav2 ul a[target=_blank] {
    padding-right: 22px;
    position: relative;
  }
  .footer__nav2 ul a[target=_blank]::after {
    content: "";
    display: block;
    width: 15px;
    height: 10px;
    background-image: url(../images/common/icon_blank.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    position: absolute;
    right: 0;
    top: 50%;
    transition: all 0.3s ease;
    transform: translateY(-50%);
  }
  .footer__nav2 .copyright {
    color: #C0DBEC;
    font-size: 12px;
    letter-spacing: 0;
  }
}

/************************
共通　ハンバーガーメニュー（スマホ）
************************/
#hamburger_button {
  display: block;
  margin: 0;
  padding: 35px 0 0;
  width: 50px;
  height: 50px;
  color: #FFF;
  font-size: 10px;
  text-align: center;
  line-height: 1;
  background-color: #0082D6;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
  cursor: pointer;
}
#hamburger_button span {
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}
#hamburger_button span::before, #hamburger_button span::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  border-bottom: 2px solid #FFF;
  position: absolute;
  left: 0;
  transition: 0.6s;
}
#hamburger_button span::before {
  top: 30%;
}
#hamburger_button span::after {
  top: 60%;
}

.menu-open #hamburger_button {
  font-size: 0;
}
.menu-open #hamburger_button span {
  top: 50%;
}
.menu-open #hamburger_button span::before {
  top: 50%;
  transform: rotate(45deg);
}
.menu-open #hamburger_button span::after {
  top: 50%;
  transform: rotate(-45deg);
}

@media screen and (min-width: 992px) {
  #hamburger_button {
    display: none;
  }
}
/************************
アニメーション
************************/
.scrollEffect {
  transition: all 1500ms cubic-bezier(0.395, 0.085, 0.15, 0.86); /* custom */
  transition-timing-function: cubic-bezier(0.395, 0.085, 0.15, 0.86); /* custom */
}

.fadeIn {
  opacity: 0;
}
.fadeIn.view {
  opacity: 1;
}

.fadeblur {
  transform: scale(1.1) translateY(20px);
  filter: blur(20px);
  opacity: 0;
}
.fadeblur.view {
  transform: scale(1) translateY(0);
  filter: blur(0);
  opacity: 1;
}

.fadeIn {
  opacity: 0;
}
.fadeIn.view {
  opacity: 1;
}

.fadeIn-left {
  opacity: 0;
  transform: translateX(-100px);
}
.fadeIn-left.view {
  opacity: 1;
  transform: translateX(0);
}

.fadeIn-right {
  opacity: 0;
  transform: translateX(100px);
}
.fadeIn-right.view {
  opacity: 1;
  transform: translateX(0);
}

.fadeIn-UP {
  opacity: 0;
  transform: translateY(100px);
}
.fadeIn-UP.view {
  opacity: 1;
  transform: translateY(0);
}

@media screen and (max-width: 767px) {
  .pc {
    display: none !important;
  }
}
@media screen and (min-width: 768px) {
  .sp {
    display: none !important;
  }
}
@media screen and (max-width: 991px) {
  .pc_large {
    display: none !important;
  }
}
@media screen and (min-width: 992px) {
  .sp_large {
    display: none !important;
  }
}
/************************
root
************************/
#root {
  position: absolute;
  top: 0;
  width: 100%;
}

/************************
wrapper
************************/
.wrapper {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
}

/************************
page-header
************************/
.page-header {
  margin-top: 50px;
  padding: 36px 15px 0;
  height: 326px;
  color: #FFF;
  background-image: url(../images/common/ph_bg.jpg);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}
.page-header .inner {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 1100px;
  position: relative;
}
.page-header .textarea h1 {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.05em;
}
.page-header .textarea p {
  margin-top: 20px;
  font-size: 14px;
}
.page-header .img {
  margin: 0;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}
.page-header .img img {
  display: block;
}

@media screen and (min-width: 768px) {
  .page-header {
    margin-top: 50px;
    padding: 80px 15px 0;
    height: 320px;
    background-position: 50% 28%;
    background-size: cover;
  }
  .page-header .inner {
    display: flex;
  }
  .page-header .textarea {
    width: 54%;
    max-width: 700px;
  }
  .page-header .textarea h1 {
    font-size: 32px;
    font-weight: 700;
    text-align: left;
  }
  .page-header .textarea p {
    margin-top: 30px;
    font-size: 13px;
  }
  .page-header .img {
    margin: 0;
    position: absolute;
    left: auto;
    right: 0;
    bottom: 0;
    transform: none;
  }
}
@media screen and (min-width: 992px) {
  .page-header {
    margin-top: 100px;
  }
  .page-header .textarea {
    width: 64%;
  }
  .page-header .textarea h1 {
    font-size: 43px;
  }
  .page-header .textarea p {
    font-size: 15px;
  }
}
/************************
page-footer
************************/
.page-footer {
  padding: 28px 15px 25px;
  color: #FFF;
  background-color: #0072BC;
}
.page-footer .inner {
  margin: 0 auto;
  max-width: 980px;
}
.page-footer__head {
  text-align: center;
}
.page-footer__head .img {
  margin: 0 auto;
  width: 100px;
}
.page-footer__head h2 {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 700;
}
.page-footer__head p {
  margin-top: 5px;
  font-size: 13px;
}
.page-footer__body {
  margin-top: 15px;
}
.page-footer__body .flexBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 -7px;
}
.page-footer__body .flexItem {
  padding: 0 7px;
  width: 50%;
}
.page-footer__body .linkBtn {
  margin: 7px 0;
}

@media screen and (min-width: 768px) {
  .page-footer {
    padding: 60px 15px 55px;
  }
  .page-footer__head .flexBox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0 -20px;
  }
  .page-footer__head .flexItem {
    padding: 0 20px;
  }
  .page-footer__head .img {
    width: 120px;
  }
  .page-footer__head h2 {
    margin: 0;
    font-size: 30px;
    font-weight: 700;
    text-align: left;
    letter-spacing: 0.05em;
  }
  .page-footer__head p {
    margin-top: 18px;
    font-size: 16px;
    text-align: left;
  }
  .page-footer__body .flexBox {
    margin: 0 -20px;
  }
  .page-footer__body .flexItem {
    padding: 0 20px;
    width: 33.3333%;
  }
}
/************************
パンくずリスト
************************/
.breadcrumb {
  margin: 0;
  padding: 0 15px;
  border-top: 1px solid #C0DBEC;
  overflow-x: auto;
  -ms-overflow-style: none;
}
.breadcrumb::-webkit-scrollbar {
  display: none;
}
.breadcrumb__in {
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 10px 0;
  width: 100%;
  max-width: 1100px;
  line-height: 1.2;
  white-space: nowrap;
}
.breadcrumb span,
.breadcrumb a {
  display: inline-block;
  margin-right: 27px;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0;
}
.breadcrumb a {
  color: #0072BC;
  text-decoration: none;
  position: relative;
}
.breadcrumb a::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 7px;
  border-color: transparent transparent transparent #cccccc;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(11px, -50%);
}
.breadcrumb span {
  padding-right: 20px;
}

@media screen and (min-width: 768px) {
  .breadcrumb__in {
    padding: 14px 0;
  }
  .breadcrumb span,
  .breadcrumb a {
    font-size: 13px;
  }
  .breadcrumb a {
    margin-right: 40px;
  }
  .breadcrumb a::after {
    transform: translate(16px, -50%);
  }
}
/************************
アーカイブ用　ページなーション
************************/
.pagenation {
  margin: 0;
  padding: 60px 0 0;
  text-align: center;
  font-size: 0;
}
.pagenation__in {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagenation .page-numbers {
  display: inline-block;
  font-size: 18px;
}
.pagenation a,
.pagenation a:hover,
.pagenation span {
  display: inline-block;
  margin: 0 10px;
  width: 40px;
  height: 40px;
  color: #6b3a20;
  font-family: "Nunito", sans-serif;
  font-size: 18px;
  text-decoration: none;
  line-height: 40px;
  background-color: #ede5c6;
  border: 1px solid #ede5c6;
  border-radius: 5px;
  box-sizing: border-box;
}
.pagenation a:hover {
  color: #FFF;
  text-decoration: none;
  background-color: #7a5c49;
  border: 1px solid #7a5c49;
}
.pagenation span.dots {
  background: none;
}
.pagenation span.current {
  color: #FFF;
  background-color: #7a5c49;
  border: 1px solid #7a5c49;
}
.pagenation .prev-link,
.pagenation .next-link,
.pagenation .prev-link a,
.pagenation .next-link a {
  width: 40px;
  height: 40px;
}
.pagenation .prev-link a,
.pagenation .next-link a {
  margin: 0;
  text-indent: -9999px;
  position: relative;
}
.pagenation .prev-link a::after,
.pagenation .next-link a::after {
  content: "";
  display: block;
  margin-left: -5px;
  margin-top: -5px;
  width: 10px;
  height: 10px;
  border-right: 2px solid #6b3a20;
  border-bottom: 2px solid #6b3a20;
  position: absolute;
  left: 50%;
  top: 50%;
}
.pagenation .prev-link a:hover::after,
.pagenation .next-link a:hover::after {
  border-right: 2px solid #FFF;
  border-bottom: 2px solid #FFF;
}
.pagenation .prev-link a::after {
  transform: rotate(135deg);
}
.pagenation .next-link a::after {
  transform: rotate(-45deg);
}

@media screen and (max-width: 767px) {
  .pagenation {
    padding: 45px 0;
  }
  .pagenation__in {
    justify-content: space-between;
  }
  .pagenation_links {
    display: none;
  }
  .pagenation .prev-link,
  .pagenation .next-link,
  .pagenation .prev-link a,
  .pagenation .next-link a {
    margin: 0;
    width: 90px;
    height: 50px;
  }
}
@media screen and (min-width: 768px) {
  .pagenation {
    padding: 100px 0 0;
  }
}
/************************
投稿ページ用　ページトップ
************************/
.previous_next_post {
  margin: 50px 0;
  padding: 0;
  width: 100%;
}
.previous_next_post:after {
  content: "";
  display: table;
  clear: both;
  height: 0;
  visibility: hidden;
}
.previous_next_post .prev_post {
  float: left;
}
.previous_next_post .next_post {
  float: right;
}
.previous_next_post .prev_post,
.previous_next_post .next_post {
  width: 49%;
  height: 50px;
  max-width: 320px;
}
.previous_next_post .prev_post a,
.previous_next_post .next_post a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0 20px;
  width: 100%;
  height: 100%;
  color: #111;
  font-size: 12px;
  line-height: 1.5;
  text-decoration: none;
  background-color: #fffdf4;
  border: 1px solid #cecece;
  border-radius: 5px;
  position: relative;
}
.previous_next_post .prev_post a::after,
.previous_next_post .next_post a::after {
  content: "";
  display: block;
  margin-top: -5px;
  width: 10px;
  height: 10px;
  border-right: 2px solid #6b3a20;
  border-bottom: 2px solid #6b3a20;
  position: absolute;
  top: 50%;
}
.previous_next_post .prev_post a:hover,
.previous_next_post .next_post a:hover {
  opacity: 0.6;
}
.previous_next_post .prev_post a::after {
  left: 10px;
  transform: rotate(135deg);
}
.previous_next_post .next_post a::after {
  right: 10px;
  transform: rotate(-45deg);
}

@media screen and (min-width: 768px) {
  .pagenation_links {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .previous_next_post {
    margin: 44px 0 0;
  }
  .previous_next_post .prev_post a,
  .previous_next_post .next_post a {
    font-size: 16px;
  }
  .previous_next_post .prev_post a {
    padding: 0 20px 0 60px;
  }
  .previous_next_post .next_post a {
    padding: 0 60px 0 20px;
  }
  .previous_next_post .prev_post a::after {
    left: 32px;
  }
  .previous_next_post .next_post a::after {
    right: 32px;
  }
}