/************************
アーカイブ用　ページなーション
************************/
.pagenation {
	margin: 0;
	padding: 60px 0 0;
	text-align: center;
	font-size: 0;

	&__in {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.page-numbers {
		display: inline-block;
		font-size: 18px;
	}

	a,
	a:hover,
	span {
		display: inline-block;
		margin: 0 10px;
		width: 40px;
		height: 40px;
		color: #6b3a20;
		font-family: "Nunito", sans-serif;
		font-size: 18px;
		text-decoration: none;
		line-height: 40px;
		background-color: #ede5c6;
		border: 1px solid #ede5c6;
		border-radius: 5px;
		box-sizing: border-box;
	}

	a:hover {
		color: #FFF;
		text-decoration: none;
		background-color: #7a5c49;
		border: 1px solid #7a5c49;
	}

	span.dots {
		background: none;
	}

	span.current {
		color: #FFF;
		background-color: #7a5c49;
		border: 1px solid #7a5c49;
	}

	.prev-link,
	.next-link,
	.prev-link a,
	.next-link a {
		width: 40px;
		height: 40px;
	}

	.prev-link a,
	.next-link a {
		margin: 0;
		text-indent: -9999px;
		position: relative;

		&::after {
			content: "";
			display: block;
			margin-left: -5px;
			margin-top: -5px;
			width: 10px;
			height: 10px;
			border-right: 2px solid #6b3a20;
			border-bottom: 2px solid #6b3a20;
			position: absolute;
			left: 50%;
			top: 50%;
		}

		&:hover {
			&::after {
				border-right: 2px solid #FFF;
				border-bottom: 2px solid #FFF;
			}
		}
	}
	.prev-link a::after {
		transform: rotate(135deg);
	}

	.next-link a::after {
		transform: rotate(-45deg);
	}
}

@media screen and (max-width: 767px) {
	.pagenation {
		padding: 45px 0;

		&__in {
			justify-content: space-between;
		}

		&_links {
			display: none;
		}

		.prev-link,
		.next-link,
		.prev-link a,
		.next-link a {
			margin: 0;
			width: 90px;
			height: 50px;
		}
	}
}

@media screen and (min-width: 768px) {
	.pagenation {
		padding: 100px 0 0;
	}
}
/************************
投稿ページ用　ページトップ
************************/
.previous_next_post {
	margin: 50px 0;
	padding: 0;
	width: 100%;

	&:after {
		content: "";
		display: table;
		clear: both;
		height: 0;
		visibility: hidden;
	}

	.prev_post {
		float: left;
	}

	.next_post {
		float: right;
	}

	.prev_post,
	.next_post {
		width: 49%;
		height: 50px;
		max-width: 320px;

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0;
			padding: 0 20px;
			width: 100%;
			height: 100%;
			color: #111;
			font-size: 12px;
			line-height: 1.5;
			text-decoration: none;
			background-color: #fffdf4;
			border: 1px solid #cecece;
			border-radius: 5px;
			position: relative;

			&::after {
				content: "";
				display: block;
				margin-top: -5px;
				width: 10px;
				height: 10px;
				border-right: 2px solid #6b3a20;
				border-bottom: 2px solid #6b3a20;
				position: absolute;
				top: 50%;
			}

			&:hover {
				opacity: 0.6;
			}
		}
	}

	.prev_post a::after {
		left: 10px;
		transform: rotate(135deg);
	}

	.next_post a::after {
		right: 10px;
		transform: rotate(-45deg);
	}
}

@media screen and (min-width: 768px) {
	.pagenation_links {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.previous_next_post {
		margin: 44px 0 0;

		.prev_post,
		.next_post {
			a {
				font-size: 16px;
			}
		}

		.prev_post a {
			padding: 0 20px 0 60px;
		}

		.next_post a {
			padding: 0 60px 0 20px;
		}

		.prev_post a::after {
			left: 32px;
		}

		.next_post a::after {
			right: 32px;
		}
	}
}
