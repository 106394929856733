/************************
共通　ヘッダー
************************/
.site-header {
  display: flex;
  align-items: center;
  padding: 0 15px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  transition: all 0.3s ease;

  @media screen and (max-width: 991px) {
    height: 50px;
  }
  @media screen and (min-width: 992px) {
    height: 100px;
  }

  &__inner {
    @media screen and (min-width: 992px) {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin: 0 auto;

      width: 100%;
      height: 100px;
      max-width: 1100px;

      position: relative;
      z-index: 2;
    }
  }

  &.fixed {
    background-color: rgba(255, 255, 255, 0.9);
  }
}

.header {
  &__logo {
    @media screen and (max-width: 991px) {
      width: 125px;
    }
    @media screen and (min-width: 992px) {
      width: 230px;
    }

    a {
      display: block;
      line-height: 1;
    }
  }

  &__gnavi {
    @media screen and (max-width: 991px) {
      padding: 40px 15px 100px;
      width: 100vw;
      color: #fff;
      background-color: #00548b;
      position: absolute;
      right: 0;
      top: 100%;
      z-index: 1;

      // transition: all 0.3s ease;
      opacity: 0;
      pointer-events: none;
      visibility: hidden;

      &__msg {
        font-size: 14px;
        font-weight: 700;
        text-align: center;
      }

      .contactBtn {
        margin-top: 10px;
      }

      nav {
        margin-top: 20px;
        padding-bottom: 80px;
        width: 100%;
        height: 72vh;
        overflow: auto;
        -ms-overflow-style: none;
      }

      nav::-webkit-scrollbar {
        display: none;
      }

      ul,
      li {
        margin: 0;
        padding: 0;
      }

      ul.menu {
        a,
        span {
          display: block;
          color: #fff;
          font-size: 17px;
          font-weight: 700;
        }

        a {
          text-decoration: none;
        }

        & > li {
          padding: 16px 0;
          border-bottom: 1px solid #fff;

          & > span {
            position: relative;

            &::after {
              content: "";
              display: block;
              width: 20px;
              height: 20px;
              background-image: url(../images/common/icon_open.png);
              background-repeat: no-repeat;
              background-position: 0 0;
              background-size: contain;
              position: absolute;
              right: 0;
              top: 50%;
              transition: all 0.3s ease;
              transform: translateY(-50%);
            }

            &.open {
              &::after {
                background-image: url(../images/common/icon_close.png);
              }
            }
          }

          em {
            color: #fff;
            font-size: 17px;
            font-weight: 700;
            font-style: normal;
          }
        }
      }

      ul.sub {
        display: none;
        padding: 8px 0 0;

        & > li {
          padding: 6px 0;

          & > a {
            padding: 0 10px;
            color: #ceebff;
            font-size: 14px;
            font-weight: 400;

            & > span {
              color: #ceebff;
              font-size: 14px;
              font-weight: 400;
            }
          }

          em {
            color: #ceebff;
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
          }
        }
      }

      ul.group {
        & > li {
          padding: 6px 0;

          a {
            padding: 0 10px;
            color: #ceebff;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
    @media screen and (min-width: 992px) {
      padding: 48px 0 13px;

      .contactBtn {
        position: absolute;
        right: 0;
        top: 0;

        a {
          padding-right: 20px;
          width: 195px;
          height: 40px;
          font-size: 17px;
          border-radius: 0 0 6px 6px;

          &::after {
            right: 25px;
          }

          &:hover {
            &::after {
              right: 20px;
            }
          }
        }
      }

      nav {
        margin: 0;
        padding: 0;
      }

      ul,
      li {
        margin: 0;
        padding: 0;
      }

      ul.menu {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0 -2px;

        a,
        span {
          display: block;
          font-size: 10px;
          font-weight: 500;
        }

        a {
          color: #333;
          text-decoration: none;

          &:hover {
            color: #0071bc;
            opacity: 1;
          }
        }

        & > li {
          padding: 0 2px;

          & > a,
          & > span {
            padding-bottom: 5px;
            position: relative;
            cursor: pointer;

            transition: all 0.3s ease;

            &::after {
              content: "";
              display: block;
              margin-left: -6px;
              width: 12px;
              height: 8px;
              background-image: url(../images/common/icon_arw_gm.svg);
              background-repeat: no-repeat;
              background-position: 0 0;
              background-size: contain;
              position: absolute;
              left: 50%;
              bottom: 0;
              transition: all 0.3s ease;
            }

            &:hover {
              color: #0071bc;
              opacity: 1;

              &::after {
                transform: translateY(5px);
              }
            }

            &.no-arrow {
              &::after {
                display: none;
              }
            }
          }
        }
      }

      ul.sub {
        display: flex !important;
        width: calc(100vw - 30px);
        max-width: 1100px;
        position: absolute;
        top: 100%;
        left: 0;

        pointer-events: none;
        visibility: hidden;
        opacity: 0;

        transition: all 0.3s ease;

        &::before,
        &::after {
          content: "";
          display: block;
          width: 70vw;
          height: 100%;
          position: absolute;
          top: 0;
        }
        &::before {
          background-color: #004674;
          right: 50%;
        }
        &::after {
          background-color: #fff;
          left: 50%;
        }

        & > li {
          display: flex;
          width: 100%;
          color: #fff;
          background-color: #004674;
          position: relative;
          z-index: 1;

          & > a {
            display: flex;
            padding: 40px 0 0;
            width: 250px;
            color: #fff;

            &:hover {
              color: #fff;
              opacity: 0.6;
            }

            & > span {
              display: inline-block;
              padding-right: 40px;
              font-size: 12px;
              font-weight: 400;
              line-height: 1;
              letter-spacing: 0.05em;
              position: relative;

              &::after {
                content: "";
                display: block;
                width: 24px;
                height: 24px;
                background-image: url(../images/common/icon_arw_btn2.svg);
                background-repeat: no-repeat;
                background-position: 0 0;
                background-size: contain;
                position: absolute;
                right: 0;
                top: -5px;
              }
            }
          }
        }
      }

      ul.group {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 60px;
        width: calc(100% - 250px);
        background-color: #fff;

        position: relative;
        z-index: 1;

        & > li {
          margin: 10px 0;
          width: 50%;

          & > a {
            display: inline-block;
            padding-right: 30px;
            color: #333;
            font-size: 12px;
            font-weight: 500;
            position: relative;

            &::after {
              content: "";
              display: block;
              width: 20px;
              height: 20px;
              background-image: url(../images/common/icon_arw_btn3.svg);
              background-repeat: no-repeat;
              background-position: 0 0;
              background-size: contain;
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
            }

            &:hover {
              color: #333;
              opacity: 0.6;
            }
          }
        }

        & + .js-closeBtn {
          display: block;
          width: 20px;
          height: 20px;
          background-image: url(../images/common/icon_close2.png);
          background-repeat: no-repeat;
          background-position: 0 0;
          background-size: contain;
          position: absolute;
          right: 20px;
          top: 30px;
          z-index: 1;

          transition: all 0.3s ease;
          cursor: pointer;

          &:hover {
            opacity: 6;
          }
        }
      }
    }
    @media screen and (min-width: 992px) {
      ul.menu {
        margin: 0 -12px;
        width: max-content;

        a,
        span {
          font-size: 13px;
        }

        & > li {
          padding: 0 12px;
        }
      }

      ul.sub {
        & > li {
          & > a {
            padding: 50px 0 0;
            width: 310px;

            & > span {
              font-size: 22px;

              &::after {
                top: 0;
              }
            }
          }
        }
      }

      ul.group {
        padding: 35px 75px 35px 65px;
        width: calc(100% - 290px);

        & > li {
          & > a {
            font-size: 16px;
          }
        }

        & + .js-closeBtn {
          top: 53px;
        }
      }
    }
    @media screen and (min-width: 1200px) {
      padding: 48px 0 13px;

      ul.menu {
        margin: 0 -17px;
        width: max-content;

        a,
        span {
          font-size: 15px;
        }

        & > li {
          padding: 0 17px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .menu-open .header__gnavi {
    transition: all 0.3s ease;
    opacity: 1;
    pointer-events: all;
    visibility: visible;
  }
}

@media screen and (min-width: 992px) {
  .js-gmenuBtn.open + ul.sub {
    pointer-events: all;
    visibility: visible;
    opacity: 1;
  }
}

@media screen and (min-width: 992px) {
  .overlay {
    display: block;
    width: 100vw;
    height: calc(100vh - 100px);
    background-color: rgba(51, 51, 51, 0.4);

    position: fixed;
    top: 100px;
    left: 0;
    z-index: 1;

    pointer-events: none;
    visibility: hidden;
    opacity: 0;
  }

  .overlay.open {
    pointer-events: all;
    visibility: visible;
    opacity: 1;
  }
}

body.fixed {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
}
