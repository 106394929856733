/************************
共通　フッター
************************/
.site-footer {
	border-top: 1px solid #C0DBEC;

	&__inner{

		&.footer1{
			background-color: #FFF;

			@media screen and (min-width: 768px) {
				margin: 0 auto;
				padding: 0 20px;
				width: 100%;
				max-width: 1140px;
			}
		}

		&.footer2{
			color: #FFF;
			background-color: #004674;
		}
	}
}

.footer{

	&__gnavi {
		@media screen and (max-width: 991px) {
			padding: 38px 15px;

			&__msg{
				font-size: 14px;
				font-weight: 700;
				text-align: center;
			}

			.contactBtn{
				margin-top: 10px;
			}

			nav {
				margin-top: 25px;
				width: 100%;
			}

			ul, li{
				margin: 0;
				padding: 0;
			}

			ul.menu {

				a, span{
					display: block;
					color: #0072BC;
					font-size: 15px;
					font-weight: 700;
				}

				a{
					text-decoration: none;
				}

				& > li {
					padding: 10px 0;
					border-bottom: 1px solid #C0DBEC;

					span{
						position: relative;

						&::after{
							content: "";
							display: block;
							width: 20px;
							height: 20px;
							background-image: url(../images/common/icon_open2.png);
							background-repeat: no-repeat;
							background-position: 0 0;
							background-size: contain;
							position: absolute;
							right: 0;
							top: 50%;
							transition: all 0.3s ease;
							transform: translateY(-50%);
						}

						&.open{
							&::after{
								background-image: url(../images/common/icon_close2.png);
							}
						}
					}
				}
			}

			ul.sub {
				display: none;
				margin-top: 10px;

				& > li {
					padding: 6px 0;

					a{
						padding: 0 10px;
						color: #333;
						font-size: 14px;
						font-weight: 400;
						text-decoration: none;
					}
				}
			}

			ul.group {

				& > li {
					padding: 6px 0;

					a{
						padding: 0 10px;
						color: #333;
						font-size: 14px;
						font-weight: 400;
						text-decoration: none;
					}
				}
			}
		}
		@media screen and (max-width: 767px) {
			&__tool{
				display: block;
				margin-top: 40px;

				a{
					display: block;
					margin: 0 auto;
					width: 100%;
					height: 90px;
					max-width: 300px;
					text-indent: -9999px;
					overflow: hidden;
					background-image: url(../images/common/btn_tool.png);
					background-repeat: no-repeat;
					background-position: 0 0;
					background-size: contain;
					transition: all 0.3s ease;
					position: relative;

					&::after{
						content: "";
						display: block;
						width: 24px;
						height: 24px;
						background-image: url(../images/common/icon_arw_btn2.svg);
						background-repeat: no-repeat;
						background-position: 0 0;
						background-size: contain;
						position: absolute;
						right: 30px;
						top: 46%;

						transition: all 0.3s ease;
						transform: translateY(-50%);
					}

					&:hover{
						background-image: url(../images/common/btn_tool_on.png);
						opacity: 1;

						&::after{
							right: 25px;
						}
					}
				}
			}
		}
		@media screen and (min-width: 768px) {
			&__tool{
				display: block;
				margin-top: 40px;

				a{
					display: block;
					margin: 0 auto;
					width: 100%;
					height: 90px;
					max-width: 300px;
					text-indent: -9999px;
					overflow: hidden;
					background-image: url(../images/common/btn_tool.png);
					background-repeat: no-repeat;
					background-position: 0 0;
					background-size: contain;
					transition: all 0.3s ease;
					position: relative;

					&::after{
						content: "";
						display: block;
						width: 24px;
						height: 24px;
						background-image: url(../images/common/icon_arw_btn2.svg);
						background-repeat: no-repeat;
						background-position: 0 0;
						background-size: contain;
						position: absolute;
						right: 30px;
						top: 46%;

						transition: all 0.3s ease;
						transform: translateY(-50%);
					}

					&:hover{
						background-image: url(../images/common/btn_tool_on.png);
						opacity: 1;

						&::after{
							right: 25px;
						}
					}
				}
			}
		}
		@media screen and (min-width: 992px) {
			padding: 40px 0 33px;

			&__info{
				margin: 0 auto;
				width: 408px;
				text-align: center;
			}

			&__msg{
				margin: 0;
				font-size: 16px;
				font-weight: 700;
			}

			.contactBtn{
				margin-top: 15px;

				a{
					width: 408px;
					height: 90px;
					font-size: 24px;

					&::after{
						width: 24px;
						height: 24px;
						right: 30px;
					}

					&:hover{

						&::after{
							right: 25px;
						}
					}
				}
			}

			&__tool{
				a{
					max-width: 100%;
				}
			}

			nav {
				display: flex;
				margin-top: 40px;
				width: 100%;
			}

			ul, li{
				margin: 0;
				padding: 0;
			}

			ul.menu {
				width: 50%;

				&:nth-of-type(2){
					margin-top: 48px;
				}

				a, span{
					display: block;
					padding: 0 0 0 35px;
					color: #0072BC;
					font-size: 16px;
					font-weight: 700;
				}

				a{
					text-decoration: none;
				}

				& > li {
					margin: 15px 0;

					& > a, & > span{
						position: relative;

						&::before{
							content: "";
							display: block;
							width: 18px;
							height: 18px;
							background-image: url(../images/common/icon_arw_btn3.svg);
							background-repeat: no-repeat;
							background-position: 0 0;
							background-size: contain;
							position: absolute;
							left: 0;
							top: 50%;
							transition: all 0.3s ease;
							transform: translateY(-50%);
						}
					}
				}
			}

			ul.sub{
				display: block !important;

				&.sp{
					display: none !important;
				}
			}

			ul.group {

				& > li {
					padding: 4px 0;

					a{
						padding: 0 35px;
						color: #333;
						font-size: 15px;
						font-weight: 400;
						line-height: 1.96;
					}
				}
			}
		}
		@media screen and (min-width: 1100px) {
			display: flex;
			justify-content: space-between;
			flex-direction: row-reverse;

			&__info{
				margin: 0;
				padding: 17px 0 0;
			}

			nav {
				margin: 0;
			}
		}
	}
}


.footer__nav2{
	@media screen and (max-width: 991px) {
		padding: 15px;

		ul{
			display: flex;
			flex-wrap: wrap;
			margin: 0 -10px;

			li{
				padding: 5px 10px;
			}

			a{
				display: block;
				color: #E3E3E3;
				font-size: 11px;
				letter-spacing: 0.05em;

				&[target="_blank"]{
					padding-right: 20px;
				}
			}

			a[target="_blank"]{
				position: relative;

				&::after{
					content: "";
					display: block;
					width: 15px;
					height: 10px;
					background-image: url(../images/common/icon_blank.svg);
					background-repeat: no-repeat;
					background-position: 0 0;
					background-size: contain;
					position: absolute;
					right: 0;
					top: 50%;
					transition: all 0.3s ease;
					transform: translateY(-50%);
				}
			}
		}

		.copyright{
			margin-top: 5px;
			color: #C0DBEC;
			font-size: 11px;
			letter-spacing: 0;
		}
	}
	@media screen and (min-width: 992px) {
		display: flex;
    align-items: center;
    justify-content: space-between;
		margin: 0 auto;
		padding: 16px 20px;
		width: 100%;
    max-width: 1140px;

		ul{
			display: flex;
			gap: 0 35px;

			a{
				display: block;
				color: #E3E3E3;
				font-size: 13px;
				text-decoration: underline;
			}

			a[target="_blank"]{
				padding-right: 22px;
				position: relative;

				&::after{
					content: "";
					display: block;
					width: 15px;
					height: 10px;
					background-image: url(../images/common/icon_blank.svg);
					background-repeat: no-repeat;
					background-position: 0 0;
					background-size: contain;
					position: absolute;
					right: 0;
					top: 50%;

					transition: all 0.3s ease;
					transform: translateY(-50%);
				}
			}
		}

		.copyright{
			color: #C0DBEC;
			font-size: 12px;
			letter-spacing: 0;
		}
	}
}