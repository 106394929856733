@media screen and (max-width: 767px) { .pc{ display: none !important;} }
@media screen and (min-width: 768px) { .sp{ display: none !important;} }

@media screen and (max-width: 991px) { .pc_large{ display: none !important;} }
@media screen and (min-width: 992px) { .sp_large{ display: none !important;} }

/************************
root
************************/
#root {
	position: absolute;
	top: 0;
	width: 100%;
}

/************************
wrapper
************************/
.wrapper {
	margin: 0;
	padding: 0;
	width: 100%;
	overflow: hidden;
}

/************************
page-header
************************/
.page-header{
	margin-top: 50px;
	padding: 36px 15px 0;
	height: 326px;
	color: #FFF;
	background-image: url(../images/common/ph_bg.jpg);
	background-repeat: no-repeat;
	background-position: 50% 0;
	background-size: cover;

	.inner{
		margin: 0 auto;
		width: 100%;
		height: 100%;
		max-width: 1100px;
		position: relative;
	}

	.textarea{

		h1{
			font-size: 30px;
			font-weight: 700;
			text-align: center;
			letter-spacing: 0.05em;
		}

		p{
			margin-top: 20px;
			font-size: 14px;
		}
	}

	.img{
		margin: 0;
		position: absolute;
		left: 50%;
		bottom: 0;
		transform: translateX(-50%);

		img{
			display: block;
		}
	}
}
@media screen and (min-width:768px){
	.page-header{
		margin-top: 50px;
		padding: 80px 15px 0;
		height: 320px;
		background-position: 50% 28%;
		background-size: cover;

		.inner{
			display: flex;
		}

		.textarea{
			width: 54%;
			max-width: 700px;

			h1{
				font-size: 32px;
				font-weight: 700;
				text-align: left;
			}
	
			p{
				margin-top: 30px;
				font-size: 13px;
			}
		}
	
		.img{
			margin: 0;
			position: absolute;
			left: auto;
			right: 0;
			bottom: 0;
			transform: none;
		}
	}	
}
@media screen and (min-width:992px){
	.page-header{
		margin-top: 100px;

		.textarea{
			width: 64%;

			h1{
				font-size: 43px;
			}
			p{
				font-size: 15px;
			}
		}
	}
}



/************************
page-footer
************************/
.page-footer{
	padding: 28px 15px 25px;
	color: #FFF;
	background-color: #0072BC;

	.inner{
		margin: 0 auto;
		max-width: 980px;
	}

	&__head{
		text-align: center;

		.img{
			margin: 0 auto;
			width: 100px;
		}

		h2{
			margin-top: 10px;
			font-size: 20px;
			font-weight: 700;
		}

		p{
			margin-top: 5px;
			font-size: 13px;
		}
	}

	&__body{
		margin-top: 15px;

		.flexBox{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			margin: 0 -7px;
		}

		.flexItem{
			padding: 0 7px;
			width: 50%;
		}

		.linkBtn{
			margin: 7px 0;
		}
	}
}
@media screen and (min-width:768px){
	.page-footer{
		padding: 60px 15px 55px;

		&__head{

			.flexBox{
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				margin: 0 -20px;
			}

			.flexItem{
				padding: 0 20px;
			}

			.img{
				width: 120px;
			}

			h2{
				margin: 0;
				font-size: 30px;
				font-weight: 700;
				text-align: left;
				letter-spacing: 0.05em;
			}
	
			p{
				margin-top: 18px;
				font-size: 16px;
				text-align: left;
			}

		}

		&__body{

			.flexBox{
				margin: 0 -20px;
			}

			.flexItem{
				padding: 0 20px;
				width: 33.3333%;
			}
		}
	}
}
