/************************
リセット
************************/
*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

* {
	margin: 0;
	padding: 0;
}

html {
	font-size: 62.5%; /* 10px */
}

body {
	width: 100%;
	color: #333;
	font-family: 'Noto Sans JP', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', sans-serif;
	font-size: 14px;
	line-height: 1.92;
	letter-spacing: 0.05em;
	word-break: break-word;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
}
@media screen and (min-width:768px){
	body {
		font-size: 16px;
		line-height: 2.25;
		letter-spacing: 0.05em;
	}
}

/*----------------------------------------
	HTML5
----------------------------------------*/
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

/*----------------------------------------
	Text
----------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
	line-height: 1.4;
}

pre {
	margin: 1.5em 25px;
	padding: 1em;
	overflow: scroll;
	line-height: 1.9;
}

blockquote {
	margin: 1.5em 1px;
	border: 5px solid #9f998f;
	color: #77471c;
	background-color: #f5f5f5;
	background-repeat: repeat-x;
	background-position: left bottom;
}

blockquote[title]::before {
	padding: 0.2em 10px 0.4em 10px;
	content: attr(title);
	display: block;
	color: #FFF;
	background-color: #9f998f;
	font-weight: bold;
}

blockquote[cite]::after {
	margin: 0.5em 10px;
	content: attr(cite);
	display: block;
	text-align: right;
	font-size: 85%;
}

blockquote * {
	color: #333 !important;
}

em {
	color: #000;
}

pre {
	background: #eee;
	font-size: 15px;
	font-size: 1.5rem;
	line-height: 1.6;
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 1.6em;
}

pre em {
	font-weight: bold;
	background-image: none;
}

q {
	text-decoration: underline;
}

b,
strong {
	font-weight: bold;
}

cite {
	display: block;
	text-align: right;
}

dfn,
cite,
em,
i {
	font-style: italic;
}

kbd {
	background-color: #f5f5f5;
	text-transform: uppercase;
	padding: 0 0.4em;
	border: 1px solid #6a8eaa;
}

del {
	text-decoration: line-through;
}

ins {
	border-top: 10px solid #3cadd6;
	border-bottom: 10px solid #3cadd6;
	border: 7px solid #3cadd6;
	margin: 2em 1px;
	padding: 1px 0;
	display: block;
	text-decoration: none;
}

mark {
	background: #fff9c0;
	text-decoration: none;
}

ins * {
	color: #666 !important;
	background: #fff9c0;
	text-decoration: none;
}

abbr,
acronym {
	border-bottom: 1px dotted #666;
	cursor: help;
}

address {
	margin: 0 0 1.5em;
}

sup,
sub {
	font-size: 75%;
	height: 0;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	bottom: 1ex;
}

sub {
	top: 0.5ex;
}

small {
	font-size: 75%;
}

big {
	font-size: 125%;
}

hr {
	background-color: #ccc;
	border: 0;
	height: 1px;
	margin-bottom: 1.5em;
}

/*----------------------------------------
	List
----------------------------------------*/

ul,
ol,
li,
dl,
dt,
dd {
	margin: 0;
	padding: 0;
	list-style: none;
	font-weight: normal;
}

/*----------------------------------------
	Image
----------------------------------------*/

figure {
	margin: 0;
	line-height: 1;
}

img {
	height: auto; /* Make sure images are scaled correctly. */
	max-width: 100%;
	border: none;
	vertical-align: baseline;
	-webkit-backface-visibility: hidden;
	image-rendering: -webkit-optimize-contrast;
}

/*----------------------------------------
	Anchor
----------------------------------------*/
a {
	color: #0072BC;
	text-decoration: underline;
	transition: opacity 0.3s ease;
}

a:hover,
a:focus,
a:active {
	outline: none;
	text-decoration: none;
}

a:hover {
	text-decoration: none;
	opacity: 0.6;
}

@media screen and (min-width: 768px) {
	a[href^="tel:"] {
		pointer-events: none;
		text-decoration: none;
	}
}


/*----------------------------------------
	P
----------------------------------------*/
p {
	margin: 0;
	padding: 0;
}


/*----------------------------------------
	Table
----------------------------------------*/
table {
	margin: 0;
	width: 100%;
	border-collapse: collapse;
}

/*----------------------------------------
	Form
----------------------------------------*/
fieldset,
legend {
	border: none;
}

input[type="text"],
textarea {
	padding: 0.2em;
	border: 1px solid #d1d1d1;
	background-color: #FFF;
}

input {
	line-height: 1.2;
}

select,
input,
textarea,
button {
	vertical-align: middle;
	position: relative;
	margin-right: 3px;
	top: 0;
}

input[type="checkbox"],
input[type="radio"] {
	border: none;
	top: -1px;
	margin-right: 2px;
}

input[type="text"]:focus,
textarea:focus {
	border: 0;
	background: none;
}

input.button {
	padding: 0;
	border: none;
	background-image: none;
	vertical-align: top;
}

/* iOSでのデフォルトスタイルをリセット */

input[type="submit"],
input[type="button"] {
	border-radius: 0;
	-webkit-box-sizing: content-box;
	-webkit-appearance: button;
	appearance: button;
	border: none;
	box-sizing: border-box;
	cursor: pointer;
}
input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
	display: none;
}
input[type="submit"]:focus,
input[type="button"]:focus {
	outline-offset: -2px;
}
