/************************
パンくずリスト
************************/
.breadcrumb {
	margin: 0;
	padding: 0 15px;
	border-top: 1px solid #C0DBEC;
	overflow-x: auto;
	-ms-overflow-style: none;

	&::-webkit-scrollbar {  
		display: none;
	}

	&__in {
		display: flex;
		align-items: center;
		margin: 0 auto;
		padding: 10px 0;
		width: 100%;
		max-width: 1100px;
		line-height: 1.2;
		white-space: nowrap
	}

	span,
	a {
		display: inline-block;
		margin-right: 27px;
		font-size: 11px;
		font-weight: 400;
		letter-spacing: 0;
	}

	a {
		color: #0072BC;
		text-decoration: none;
		position: relative;

		&::after{
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 5px 0 5px 7px;
			border-color: transparent transparent transparent #cccccc;

			position: absolute;
			left: 100%;
			top: 50%;
			transform: translate(11px, -50%);
		}
	}

	span{
		padding-right: 20px;
	}
}
@media screen and (min-width: 768px) {
	.breadcrumb {
		&__in {
			padding: 14px 0;
		}

		span,
		a {
			font-size: 13px;
		}

		a{
			margin-right: 40px;

			&::after{
				transform: translate(16px, -50%);
			}
		}
	}
}
