/************************
共通　ハンバーガーメニュー（スマホ）
************************/
#hamburger_button {
	display: block;
	margin: 0;
	padding: 35px 0 0;
	width: 50px;
	height: 50px;
	color: #FFF;
	font-size: 10px;
	text-align: center;
	line-height: 1;
	background-color: #0082D6;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 100;
	cursor: pointer;

	span {
		display: block;
    width: 30px;
    height: 30px;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);

		&::before,
		&::after {
			content: "";
			display: block;
			width: 100%;
			height: 2px;
			border-bottom: 2px solid #FFF;
			position: absolute;
			left: 0;
			transition: 0.6s;
		}

		&::before {
			top: 30%;
		}

		&::after {
			top: 60%;
		}
	}
}

.menu-open #hamburger_button {
	font-size: 0;

	span {
		top: 50%;

		&::before {
			top: 50%;
			transform: rotate(45deg);
		}

		&::after {
			top: 50%;
			transform: rotate(-45deg);
		}
	}
}

@media screen and (min-width: 992px) {
	#hamburger_button {
		display: none;
	}
}
